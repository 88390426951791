<template>
  <header-navigation></header-navigation>
  <!--<section class="espaciador_contenido_principal" style="    background-size: cover;
    background-position: center center;" :style="{ backgroundImage: 'url(' + require('@/assets/imagenes/17973908.jpg') + ')' }">-->

  <div class="div_btn_subir_oculto" v-bind:class="{ div_btn_subir_visible: (datos.scroll_position > 40) }" @click.native="scrollTop()">
    <img src="../assets/iconos/flecha-arriba.svg" alt="arriba">
  </div>
  <section class="espaciador_contenido_principal">
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <div class="div_cabecera">
       <div class="items item1">
         <div class="item_texto">
           <span>Atenea Systems</span>, la solución integral que necesita su negocio.
         </div>
        <div class="item_texto_cambiante">
          <span id="typed"></span>
        </div>
         <div class="item_boton">
           <div>
             <a @click.native="go_to_form()">
               <div class="btn_contactar">
                 Contactar
               </div>
             </a>
           </div>
         </div>
       </div>
        <div class="items ocultar_movil">
          <picture>
            <source srcset="../assets/imagenes/developer.webp" type="image/webp">
            <source srcset="../assets/imagenes/developer.jpg" type="image/jpeg">
            <!-- Imagen fallback para navegadores antiguos -->
            <img style="width: 100%;"  src="../assets/imagenes/developer.jpg" alt="Atenea Systems">
          </picture>

          <!--<img style="width: 100%;" src="../assets/imagenes/20945851.webp">-->
        </div>
      </div>
    </div>
  </section>
  <section class="section_azul">
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <!--<div>
        <h1>¿Qué puede hacer Atenea?</h1>
      </div>
      <div>
        Saber que su sitio web está disponible y accesible para los visitantes es fundamental para todas las empresas.
      </div>-->
      <div class="contenido_que_hace">
        <div class="item">
          <div class="titulo">
            Soporte técnico
          </div>
          <div class="explicacion">
            Brindamos servicio a empresas o usuarios individuales que puedan tener problemas de funcionamiento en su servidor VPS.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Administración de servidores
          </div>
          <div class="explicacion">
            Nos preocupamos por la seguridad de su servidor para reducir en la mayor medida posible los riesgos para su negocio.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Servicio de hosting y dominios
          </div>
          <div class="explicacion">
            <!--Si ya tiene una web, nosotros nos encargamos de proporcionarle un hosting web y de contratar los dominios necesarios.-->
            Le contratamos los dominios necesarios y le proporcionamos y configuramos un VPS adaptado a sus necesidades.
            <!--Nos encargamos de proporcionar un VPS adaptado a sus necesidades, configurarlo y contratar los dominios necesarios.-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <div class="div_cabecera">
        <div class="items ocultar_movil">
          <picture>
            <source srcset="../assets/imagenes/itsupport.webp" type="image/webp">
            <source srcset="../assets/imagenes/itsupport.jpg" type="image/jpeg">
            <!-- Imagen fallback para navegadores antiguos -->
            <img style="width: 100%;" src="../assets/imagenes/itsupport.jpg" alt="Ateneasystems.es">
          </picture>

          <!--<img style="width: 100%;" src="../assets/imagenes/Work_6.webp">-->
        </div>
        <div class="items item1">
          <div class="item_texto_info">
            El día a día de su negocio es suficiente trabajo, deje que nosotros nos ocupemos de sus servidores, hostings y dominios.
          </div>
          <!--<div class="item_texto_cambiante">
            <span id="typed"></span>
          </div>-->
          <div class="item_boton">
            <div>
              <a @click.native="go_to_form()">
                <div class="btn_contactar">
                  Contactar
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section_azul">
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <!--<div>
        <h1>¿Para quién es Failder?</h1>
      </div>-->
      <!--<div>
        Vea cómo la completa plataforma de monitoreo Failder puede ayudarlo a delos objetivos de su sitio web.
      </div>-->
      <div class="contenido_que_hace">
        <div class="item">
          <div class="titulo">
            Desarrollo de apps
          </div>
          <div class="explicacion">
            Creamos soluciones de aplicaciones móviles personalizadas para empresas. Podemos desarrollar su aplicación desde cero o mejorar una aplicación existente para añadir nuevas funcionalidades.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Desarrollo de Webs
          </div>
          <div class="explicacion">
            Somos expertos en desarrollo de páginas web. Le ayudamos a crear su página web con la mejor relación calidad-precio, creando una experiencia digital atractiva para su negocio.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Desarrollo de APIs
          </div>
          <div class="explicacion">
           Si lo que necesita es que sus productos o servicios se comuniquen con otras empresas, le desarrollaremos una API que le otorgue flexibilidad y simplificación de uso.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <div class="div_cabecera">

        <div class="items item1">
          <div class="item_texto_info">
            Su proyecto 100% personalizado,<br>desde 0 hasta la puesta en marcha.
          </div>
          <!--<div class="item_texto_cambiante">
            <span id="typed"></span>
          </div>-->
          <div class="item_boton">
            <div>
              <a @click.native="go_to_form()">
                <div class="btn_contactar">
                  Contactar
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="items ocultar_movil">
          <picture>
            <source srcset="../assets/imagenes/Na_Dec_21.webp" type="image/webp">
            <source srcset="../assets/imagenes/Na_Dec_21.jpg" type="image/jpeg">
            <!-- Imagen fallback para navegadores antiguos -->
            <img style="width: 100%;" src="../assets/imagenes/Na_Dec_21.jpg" alt="Ateneasystems.es">
          </picture>

          <!--<img style="width: 100%;" src="../assets/imagenes/Work_6.webp">-->
        </div>
      </div>
    </div>
  </section>
  <section class="section_azul">
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <!--<div>
        <h1>¿Para quién es Failder?</h1>
      </div>-->
      <!--<div>
        Vea cómo la completa plataforma de monitoreo Failder puede ayudarlo a delos objetivos de su sitio web.
      </div>-->
      <div class="contenido_que_hace">
        <div class="item">
          <div class="titulo">
            Especialistas en UX/UI
          </div>
          <div class="explicacion">
            Una buena experiencia de usuario es esencial para su producto. Somos especialistas en crear una interfaz atractiva y funcional que resulte amigable y cómoda para el usuario final.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Diseño gráfico
          </div>
          <div class="explicacion">
            Tratamos de llegar a los usuarios para conseguir que el mensaje les impacte. Podemos realizar un diseño para una página web, aplicación móvil, marca, diseño impreso o diseño publicitario.
          </div>
        </div>
        <div class="item">
          <div class="titulo">
            Marketing digital
          </div>
          <div class="explicacion">
            El marketing online le permite diseñar estrategias específicas para su negocio, elegir los canales donde está su público objetivo y así, ganar visibilidad optimizando recursos.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <div>
        <h1>Nuestros clientes</h1>
      </div>
      <div style="margin-bottom: 40px;">
        Estos son algunos de los clientes que confían en nosotros:
      </div>
      <div class="contenido_que_hace_logos_clientes">
        <div>
          <a href="https://azulitoshop.com/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/azulitoshop.webp" type="image/webp">
              <source srcset="../assets/clientes/azulitoshop.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/azulitoshop.png" alt="Azulito shop">
            </picture>
          </a>
        </div>
        <div>
          <a href="https://casarurallacriba.es/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/casarurallacriba.webp" type="image/webp">
              <source srcset="../assets/clientes/casarurallacriba.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/casarurallacriba.png" alt="Casa rural la criba">
            </picture>
          </a>
        </div>
        <!--<div>
          <a href="https://www.electricadealginet.com/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/electricadealginet.webp" type="image/webp">
              <source srcset="../assets/clientes/electricadealginet.png" type="image/png">
              < !-- Imagen fallback para navegadores antiguos -- >
              <img src="../assets/clientes/electricadealginet.png" alt="Electrica de Alginet">
            </picture>
          </a>
        </div>-->
        <div>
          <a href="http://europadecibernetica.com" target="_blank">
            <picture>
              <source srcset="../assets/clientes/europadecibernetica.webp" type="image/webp">
              <source srcset="../assets/clientes/europadecibernetica.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/europadecibernetica.png" alt="Europa de cibernética">
            </picture>
          </a>
        </div>
        <div>
          <a href="https://www.exlibrisgibe.com/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/exlibrisgibe.webp" type="image/webp">
              <source srcset="../assets/clientes/exlibrisgibe.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/exlibrisgibe.png" alt="Exlibrisgibe">
            </picture>
          </a>
        </div>
        <div>
          <a href="https://failder.com/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/failder.webp" type="image/webp">
              <source srcset="../assets/clientes/failder.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/failder.png" alt="Failder">
            </picture>
          </a>
        </div>
        <div>
          <a href="https://www.lapcera.es/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/lapcera.webp" type="image/webp">
              <source srcset="../assets/clientes/lapcera.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/lapcera.png" alt="La PCera">
            </picture>
          </a>
        </div>
        <div>
          <a href="https://luznocturna.com/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/luznocturna.webp" type="image/webp">
              <source srcset="../assets/clientes/luznocturna.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/luznocturna.png" alt="La PCera">
            </picture>
          </a>
        </div>
        <div>
          <a href="https://mepreval.com/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/mepreval.webp" type="image/webp">
              <source srcset="../assets/clientes/mepreval.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/mepreval.png" alt="Mepreval - Mecanizados de precisión Valencia">
            </picture>
          </a>
        </div>
        <!--<div>
          <a href="https://lauraboronat.com/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/lauraboronat.webp" type="image/webp">
              <source srcset="../assets/clientes/lauraboronat.png" type="image/png">
              < !-- Imagen fallback para navegadores antiguos -- >
              <img src="../assets/clientes/lauraboronat.png" alt="Laura Boronat">
            </picture>
          </a>
        </div>-->
        <!--<div>
          <a href="https://www.plastbella.es/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/lapcera.webp" type="image/webp">
              <source srcset="../assets/clientes/lapcera.png" type="image/png">
              <! -- Imagen fallback para navegadores antiguos -- >
              <img src="../assets/clientes/lapcera.png" alt="La PCera">
            </picture>
          </a>
        </div>-->
        <div>
          <a href="https://www.trinidadmelendez.es/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/trinidadmelendez.webp" type="image/webp">
              <source srcset="../assets/clientes/trinidadmelendez.png" type="image/png">
              <!-- Imagen fallback para navegadores antiguos -->
              <img src="../assets/clientes/trinidadmelendez.png" alt="Trinidad Melendez">
            </picture>
          </a>
        </div>
        <!--<div>
          <a href="https://varlo.es/" target="_blank">
            <picture>
              <source srcset="../assets/clientes/varlo.webp" type="image/webp">
              <source srcset="../assets/clientes/varlo.png" type="image/png">
              <! -- Imagen fallback para navegadores antiguos -- >
              <img src="../assets/clientes/varlo.png" alt="Varlo">
            </picture>
          </a>
        </div>-->
      </div>
    </div>
  </section>
  <section  class="section_azul" id="div_contacto">
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <div>
        <h1>
          Formulario de contacto
        </h1>
      </div>
      <div style="margin-bottom: 32px;">
        Para cualquier duda o para pedir información sobre alguno de nuestros servicios, contacte con nosotros utilizando el siguiente formulario de contacto.
      </div>
      <div class="contenedor_form">
        <div class="form form1">
          <div class="form_item">
            <div class="titulo">
              Nombre
            </div>
            <div class="input">
              <input type="text" class="txt_nombre" v-model="datos.form.nombre">
            </div>
          </div>
          <div class="form_item">
            <div class="titulo">
              Número de Teléfono
            </div>
            <div class="input">
              <input type="tel" class="txt_tel" v-model="datos.form.tel">
            </div>
          </div>
          <div class="form_item">
            <div class="titulo">
              Email
            </div>
            <div class="input">
              <input type="email" class="txt_email" v-model="datos.form.email">
            </div>
          </div>

        </div>
        <div class="form form2">
          <div class="form_item">
            <div class="titulo">
              Consulta
            </div>
            <div class="input">
              <textarea type="text" class="txt_consulta" rows="7" v-model="datos.form.mensaje"></textarea>
            </div>
          </div>
          <div style="display:flex; flex-direction: row-reverse;">
            <div>
                <button class="btn_contactar" @click.native="enviar_formulario()" >
                  Enviar
                </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <footer class="section_footer">
    <div class="div_section" v-bind:class="{ encasillado: datos.encasillado }">
      <div class="columnas">
        <div class="bloque_column">
          <div>
            <img style="width: 140px; margin-bottom: 20px;" src="../assets/logos/logo_blanco_75.png" alt="Atenea Systems">
          </div>
          <div><a href="tel:+34644406861">(+34) 644 40 68 61</a></div>
          <div><a href="mailto:info@ateneasystems.es" >info@ateneasystems.es</a></div>
          <div style="font-size: 12px;">Puçol (València)</div>
        </div>
        <div class="bloque_column">
          <div class="titulo">Enlaces de Interés</div>
          <div class="enlace" @click="mostrar_bloqueador_cookies">
            Configurar uso de cookies
          </div>
        </div>
      </div>



      <div style="margin-top: 40px;">
        Atenea Systems © Copyright {{ datos.anyo }}. Todos los derechos reservados.
      </div>


    </div>
  </footer>
  <div class="bloqueador_cookies">
    <div class="contenido_modal">
      <div class="bloque bloque1">
        <div class="titulo">Antes de usar la web de AteneaSystems</div>
        <div class="texto">
          Utilizamos cookies propias y de terceros con fines analíticos y para mejorar tu experiencia de navegación. Puedes aceptar todas las cookies haciendo clic en 'Aceptar todas' o rechazar las cookies no esenciales seleccionando 'Rechazar'. Para personalizar tus preferencias, haz clic en 'Configurar'.
        </div>
        <div class="botonera">
          <div class="btn_cookies" @click="rechazarTodas">Rechazar</div>
          <div class="btn_cookies" @click="mostrarConfigurar">Configurar</div>
          <div class="btn_cookies btn_cookies_importante" @click="aceptarTodas">Aceptar todas</div>
        </div>
      </div>
      <div class="bloque bloque2">
        <div class="titulo">Configuración de cookies de AteneaSystems</div>
        <div class="contenido_selector">
          <div class="item_selector">
            <div class="mini_bloque_1">
              <div class="titulo_item">Cookies Técnicas</div>
              <div class="texto_item">
                Estas cookies facilitan la navegación del usuario en nuestro sitio web y le permiten acceder y utilizar diversas opciones o servicios disponibles en él, con el objetivo de mantener una experiencia fluida para el usuario que visita nuestra página web.
              </div>
            </div>
            <div  class="mini_bloque_2">
              <label class="switch">
                <input type="checkbox" data-tipo="tecnicas" checked disabled>
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="item_selector">
            <div class="mini_bloque_1">
              <div class="titulo_item">Cookies de Análisis</div>
              <div class="texto_item">
                Las cookies de análisis nos brindan información valiosa sobre la interacción de los visitantes con nuestro sitio web. Nos informan sobre las áreas más visitadas, el tiempo de permanencia en el sitio y posibles inconvenientes, como mensajes de error. Esta información es fundamental para mejorar la estructura, navegación y contenido de nuestro sitio, con el objetivo de ofrecer una experiencia óptima a nuestros visitantes. Es importante destacar que estas cookies recopilan datos de forma anónima y no realizan seguimiento de tus actividades en otros sitios web.
              </div>
            </div>
            <div class="mini_bloque_2">
              <label class="switch">
                <input type="checkbox" class="checkboxAnalisis" data-tipo="analisis">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="botonera">
          <div class="btn_cookies" @click="ocultarConfigurar">Atras</div>
          <div class="btn_cookies btn_cookies_importante" @click="guardarConfiguracionCookies">Guardar</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import HeaderNavigation from '../components/HeaderNavigation'
import ItemPrecioLicencia from "@/components/ItemPrecioLicencia";
import Typed from '../js/typed';
import Swal from 'sweetalert2';
/*window.onscroll = function (e) {
  const top = document.documentElement.scrollTop
  if (top > )
}*/


export default {
  name: 'Home',
  components: {
    HeaderNavigation,
    ItemPrecioLicencia
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    datos.scroll_position = 0;
    datos.lista_planes_venta = [];
    datos.tipo_licencia_mostrar = 1;
    datos.anyo = 2022;
    datos.aviso_lega_fijo = true;
    datos.mostrar_banner_cookies = false;

    datos.form = {};
    datos.form.nombre = '';
    datos.form.email = '';
    datos.form.tel = '';
    datos.form.mensaje = '';

    const mostrar_bloqueador_cookies = () => {
      let bloqueador = document.querySelector('.bloqueador_cookies');
      bloqueador.style.display = 'flex';
      document.body.style.overflow = 'hidden';
      console.log(' mostrar bloqueador ');
      showBlock1();
      hideBlock2();
    };

    const  ocultar_bloqueador_cookies = () => {
      let bloqueador = document.querySelector('.bloqueador_cookies');
      bloqueador.style.display = 'none';
      document.body.style.overflow = 'auto';
      console.log(' ocultar bloqueador ');
      hideBlock1();
      hideBlock2();
    };

    const showBlock1 = () => {
      let block1 = document.querySelector('.bloque1');
      block1.style.display = 'flex';
    }

    const hideBlock1 = () => {
      let block1 = document.querySelector('.bloque1');
      block1.style.display = 'none';
    }

    const showBlock2 = () => {
      let block2 = document.querySelector('.bloque2');
      block2.style.display = 'flex';
    }

    const hideBlock2 = () => {
      let block2 = document.querySelector('.bloque2');
      block2.style.display = 'none';
    }


    onMounted(function (){
      //console.log('Comprobar URL', route.fullPath)
      /*if (datos.modo_oscuro){
        document.body.classList.add("fondo_dark_theme");
        document.getElementById('app').classList.add("texto_dark_theme");
      } else {
        document.body.classList.remove("fondo_dark_theme")
        document.getElementById('app').classList.remove("texto_dark_theme");
      }*/
      console.log('aaa', localStorage.aviso_cookies);
          if (localStorage.aviso_cookies == undefined) {
            mostrar_bloqueador_cookies();
          }
          let checkboxAnalisis = document.querySelector('.checkboxAnalisis[data-tipo="analisis"]');
          if (localStorage.getItem('cookies_analytics') === 'true') {
            checkboxAnalisis.checked = true;
          } else {
            checkboxAnalisis.checked = false;
          }

      datos.typed = new Typed("#typed", {
        strings: ['Administración de serviores GNU/Linux.', 'Desarrollo de aplicaciones iOS, Android y páginas web.', 'Especialistas en UX/UI, diseño gráfico y marketing digital.', 'Soporte técnico, hosting y dominios.'],
        typeSpeed: 25,
        backSpeed: 10,
        backDelay: 1500,
        startDelay: 1000,
        loop: true,
        //onBegin: function(self) { this.prettyLog('onBegin ' + self) },
        onComplete: (self) => console.log('onCmplete ' + self),
        /*preStringTyped: function(pos, self) { this.prettyLog('preStringTyped ' + pos + ' ' + self); },
        onStringTyped: function(pos, self) { this.prettyLog('onStringTyped ' + pos + ' ' + self) },
        onLastStringBackspaced: function(self) { this.prettyLog('onLastStringBackspaced ' + self) },
        onTypingPaused: function(pos, self) { this.prettyLog('onTypingPaused ' + pos + ' ' + self) },
        onTypingResumed: function(pos, self) { this.prettyLog('onTypingResumed ' + pos + ' ' + self) },
        onReset: function(self) { this.prettyLog('onReset ' + self) },
        onStop: function(pos, self) { this.prettyLog('onStop ' + pos + ' ' + self) },
        onStart: function(pos, self) { this.prettyLog('onStart ' + pos + ' ' + self) },*/
        onDestroy: () => console.log('onDestroy ' + self)
      });




    });
    return {
      variables: variables,
      datos: datos,
      mostrar_bloqueador_cookies: mostrar_bloqueador_cookies,
      ocultar_bloqueador_cookies: ocultar_bloqueador_cookies,
      showBlock1: showBlock1,
      hideBlock1: hideBlock1,
      showBlock2: showBlock2,
      hideBlock2: hideBlock2
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    aceptarTodas() {
      this.ocultar_bloqueador_cookies();
      localStorage.setItem('aviso_cookies', 'true');
      localStorage.setItem('cookies_analytics', 'true');
      location.reload();

    },
    rechazarTodas() {
      this.ocultar_bloqueador_cookies();
      localStorage.setItem('aviso_cookies', 'true');
      localStorage.setItem('cookies_analytics', 'false');
      location.reload();
    },
    mostrarConfigurar() {
      this.showBlock2();
      this.hideBlock1();
    },
    ocultarConfigurar() {
      this.hideBlock2();
      this.showBlock1();
    },
    guardarConfiguracionCookies(){
      let checkboxAnalisis = document.querySelector('.checkboxAnalisis[data-tipo="analisis"]');
      localStorage.setItem('aviso_cookies', 'true');
      if (checkboxAnalisis.checked) {
        localStorage.setItem('cookies_analytics', 'true');
      } else {
        localStorage.setItem('cookies_analytics', 'false');
      }
      location.reload();
    },
    mostrar_mensaje_error(texto_error){
      Swal.fire({
        icon: 'error',
        title: '¡Oops!',
        text: texto_error,
      })
    },
    go_to_form(){
      let cont = 100;
      let anterior = 0;
      const elm = document.getElementById('div_contacto');
      const y_form = elm.offsetTop-50;
      /*console.log('y_form', y_form);
      console.log('window.pageYOffset', window.pageYOffset);*/
      this.intervalId = setInterval(() => {
        cont--;
        if (cont <= 0){
          clearInterval(this.intervalId);
        }
        /*if (window.pageYOffset === y_form) {
          clearInterval(this.intervalId)
        }*/
        if (window.pageYOffset - y_form <= 40 && window.pageYOffset - y_form >= 0){
          /*console.log('y_form FIN', y_form);
          console.log('window.pageYOffset FIN', window.pageYOffset);
          console.log('y_form FIN 2', window.pageYOffset - y_form);*/
          clearInterval(this.intervalId)
        }
        if(y_form > window.pageYOffset){
          window.scroll(0, window.pageYOffset + 50)
        } else {
          window.scroll(0, window.pageYOffset - 50)
        }


        /*console.log('y_form FIN', y_form);
        console.log('window.pageYOffset FIN', window.pageYOffset);
        console.log('y_form FIN 2', window.pageYOffset - y_form);*/
        if (window.pageYOffset - y_form == anterior){
          clearInterval(this.intervalId);
        } else {
          anterior = window.pageYOffset - y_form
        }
        /*console.log('y_form 1', y_form);
        console.log('window.pageYOffset 1', window.pageYOffset);*/

      }, 20)
    },
    async enviar_formulario() {
      if (this.datos.form.nombre.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el nombre.')
        return false;
      }
      if (this.datos.form.email.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el correo electrónico.')
        return false;
      }
      if (this.datos.form.mensaje.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el mensaje.')
        return false;
      }

      let datos_enviar = {
        "nombre": this.datos.form.nombre.trim(),
        "email": this.datos.form.email.trim(),
        "tel": this.datos.form.tel.trim(),
        "mensaje":this.datos.form.mensaje.trim()
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization":"Basic Y29udGFjdG86NHpVME9FZ0VkQVpoQmtMRERqZGpCMXA2N3pzWg=="},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/v1/web/contacto`, requestOptions);
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.mostrar_btn_atras = true;
        this.mostrar_mensaje_error('Se ha producido un error.\nInténtalo de nuevo.');
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        //this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
        //this.datos.mostrar_btn_atras = true;
        this.mostrar_mensaje_error('Se ha producido un error.\nInténtalo de nuevo.');
      } else {
       /* this.datos.mostrar_btn_atras = false;
        this.datos.mostrar_btn_finalizar = true;
        this.datos.mostrar_cargador = false;
        this.datos.mensaje_correcto = '¡Proceso finalizado!'*/
        this.datos.form.nombre = '';
        this.datos.form.email = '';
        this.datos.form.tel = '';
        this.datos.form.mensaje = '';
        //TODO: Requiere un mensaje de confirmacion
        Swal.fire({
          icon: 'success',
          title: '¡Mensaje enviado correctamente!',
          /*text: 'En breve te responderemos',*/
        })
      }
    },
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 150
    },
    handleScroll (event) {
      // Any code to be executed when the window is scrolled
      /*console.log('hola');
      const top = document.documentElement.scrollTop
      console.log(top);*/
    },
    prettyLog(text){
      console.log('prettyLog', text);
    },
    seleccionar_tipo_de_licencias(tipo_licencia){
      this.datos.tipo_licencia_mostrar = tipo_licencia
    },
    async get_anyo() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      const response = await fetch(`${this.datos.url_host}/v1/web/anyo/`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log(respuestaOK);
      if(respuestaOK.code != '00'){
        console.log('Respuesta error', respuestaOK.code);
        console.log('Respuesta', respuestaOK);
        return false;
      }


      let datos_recibidos = respuestaOK.data;
      this.datos.anyo = datos_recibidos;
    },
  },
  mounted() {
    this.get_anyo();
  }
}
</script>

<style lang="scss" scoped>
.div_cabecera{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-around;
  align-items: center;
}
.div_cabecera .items{
  width: 50%;
  padding-left: 30px;
  padding-right: 30px;
}

.div_cabecera .items .item_texto{
  margin-bottom: 32px;
  font-size: 32px;
}
.div_cabecera .items .item_texto_info{
  margin-bottom: 32px;
  font-size: 26px;
  text-align: justify;
}

.div_cabecera .item1{

}
.div_cabecera .items .item_texto_cambiante{
  font-size: 20px;
  margin-bottom: 32px;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
}
.div_cabecera .items .item_boton{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contenido_que_hace{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 42px;
  margin-bottom: 42px;
}
.contenido_que_hace .item{
  width: 30%;
  padding-right: 8px;
  padding-left: 8px;
}
.contenido_que_hace .item .titulo{
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.contenedor_planes_de_precio{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
}

.contenedor_form{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: flex-start;
}
.contenedor_form .form{
  width: 50%;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 10px;
}
.contenedor_form .form_item .titulo{
  text-align: left;
}

.contenedor_form .form_item{
  padding-bottom: 6px;
}
.contenido_que_hace .item .explicacion {
  text-align: justify;
}
.div_configurar_uso_cookies{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.bloqueador_cookies{
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #0000006b;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
}
.bloqueador_cookies .contenido_modal{
  background-color: #FFF;
  min-width: 600px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  max-width: 600px;
  padding: 20px;
}
.bloqueador_cookies .contenido_modal .bloque{
  flex-direction: column;
}
.bloqueador_cookies .contenido_modal .bloque .contenido_selector{
  margin-bottom: 20px;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_1{
  max-width: 400px;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_1 .titulo_item{
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_1 .texto_item{
  text-align: left;
  margin-bottom: 30px;
}
/*.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 input{
  width: 20px;
  height: 20px;
}*/
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 input:checked + .slider {
  background-color: var(--accent-color-web);
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 input:focus + .slider {
  box-shadow: 0 0 1px var(--accent-color-web);
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 .slider.round {
  border-radius: 34px;
}
.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 .slider.round:before {
  border-radius: 50%;
}



.bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_2 input{
  width: 20px;
  height: 20px;
}
.bloqueador_cookies .contenido_modal .titulo{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.bloqueador_cookies .contenido_modal .texto{
  margin-bottom: 40px;
}
.bloqueador_cookies .contenido_modal .botonera{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.section_footer .columnas{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.section_footer .columnas .bloque_column .titulo{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.section_footer .columnas .bloque_column .enlace{
  cursor: pointer;
}
@media screen and (max-width: 991px){
  .contenedor_form .form{
    width: 100%;
  }
  .div_cabecera .items{
    width: 100%;
  }
  .contenido_que_hace .item{
    width: 100%;
    padding-left: 12%;
    padding-right: 12%;
    margin-bottom: 60px;
  }

  .div_cabecera .items .item_texto{
    margin-bottom: 28px;
    font-size: 24px;
  }

  .div_cabecera .items .item_texto_cambiante{
    font-size: 18px;
    margin-bottom: 32px;
    height: 60px;
  }
  h1 {
    font-size: 1.4em;
  }
  .contenido_que_hace .item .titulo {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .contenido_que_hace .item .explicacion {
    font-size: 14px;
  }
  footer{
    font-size: 14px;
  }
  .div_cabecera .items .item_texto_info{
    margin-bottom: 32px;
    font-size: 26px;
    text-align: inherit;
  }
  .section_footer .columnas{
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
  }
  .section_footer .columnas .bloque_column{
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .bloqueador_cookies{
    align-items: flex-end;

  }
  .bloqueador_cookies .contenido_modal{
    min-width: inherit;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    max-height: 90vh;
    overflow-y: auto;
  }
  .bloqueador_cookies .contenido_modal .titulo {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .bloqueador_cookies .contenido_modal .texto {
    font-size: 12px;
  }
  .bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_1 {
    max-width: inherit;
  }
  .bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_1 .titulo_item {
    font-size: 14px;
  }
  .bloqueador_cookies .contenido_modal .bloque .item_selector .mini_bloque_1 .texto_item {
    font-size: 12px;
  }


}
</style>
